import React from "react";
import AboutUsLogo from "../../molecules/AboutUsLogo/AboutUsLogo";
import ItsAllConected from "../../molecules/ItsAllConected/ItsAllConected";
import OurMission from "../../molecules/OurMission/OurMission";
import TeamMembers from "../../molecules/TeamMembers/TeamMembers";
import * as s from "./AboutUsPage.module.scss";

const AboutUsPage = () => {
  return (
    <div className={s.container}>
      <AboutUsLogo />
      <TeamMembers />
      <OurMission />
      <ItsAllConected />
    </div>
  );
};

export default AboutUsPage;
