import React from "react";
import AboutUsPage from "../components/organisms/AboutUsPage/AboutUsPage";
import { PageTitle, SEO } from "../modules/seo";

const About = () => {
  return (
    <div>
      <SEO title={PageTitle.AboutUs} />
      <AboutUsPage />
    </div>
  );
};
export default About;
