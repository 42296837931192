import React from "react";
import * as s from "./OurMission.module.scss";
import ourMission from "../../../images/OurMission3.svg";

const OurMission = () => {
  return (
    <div className={s.container}>
      <div className={s.secondGroup}>
        <img src={ourMission} alt=""></img>
      </div>

      <div className={s.firstGroup}>
        <p className={s.paragraph}>our mission</p>
        <p className={s.secondParagraph}>
          Cousins and best friends- we’re on a mission to demystify mushrooms
          and make them approachable. We believe nature, especially mushrooms
          hold many of the answers that we’re looking for. By turning to
          preventative rather than curative health measures, we as a troop can
          walk a path to a healthier life.
        </p>
        <p className={s.secondParagraph}>
          We have seen that being healthy doesn’t have to come at a cost —
          people and planet can both come first.
        </p>
      </div>
    </div>
  );
};

export default OurMission;
