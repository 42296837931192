import React from "react";
import * as s from "./TeamMembers.module.scss";
import steph from "../../../images/Steph(1).png";
import jack from "../../../images/Jack(1).png";
import classNames from "classnames";

const cn = classNames.bind(s);

const TeamMembers = () => {
  return (
    <>
      <div className={s.container}>
        <div className={s.imageContainer}>
          <img src={steph} alt="" />
        </div>
        <div className={s.wrapper}>
          <p className={s.author}>
            Stephanie <span>Moyal</span>
          </p>
          <div className={s.bio}>
            <span>Fun(gi) facts about Steph, written by Jake:</span>
            <ul>
              <li>Steph makes tea that is 50% lemon. </li>
              <li>
                She refuses to not be the best at literally anything she does.{" "}
              </li>
              <li>
                Her most prized possession is her passport, which is why the
                Thai police are guarding it forever.
              </li>
              <li>
                I’m not sure if there is a word for dyslexia but only when it
                comes to math, but she has it.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={cn(s.container, s.reverse)}>
        <div className={s.imageContainer}>
          <img src={jack} alt="" />
        </div>
        <div className={cn(s.wrapper, s.wrapperSecond)}>
          <div className={s.contentWrapper}>
            <p className={s.author}>
              JAKE Mellman | <span>Cofounder</span>
            </p>
            <div className={cn(s.bio, s.secondBio)}>
              <span>Fun(gi) facts about Jake, written by Steph:</span>
              <ul>
                <li>
                  Jake is really bad at being an adrenaline junkie (ie. broken
                  arm, shattered leg, punctured lung). Luckily he’s still in one
                  piece and has assured everyone he has “calmed down.”
                </li>
                <li>
                  The only thing Jake ever cooks for himself is gyoza and
                  gnocchi with chicken from Trader Joes. *Editors note, Steph is
                  still trying to explain to Jake that this is called
                  ‘reheating‘.
                </li>
                <li>
                  On long car rides, Jake ties a scarf around his forehead and
                  the headrest to avoid neck pain when he wakes up. (Email us
                  for photos){" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamMembers;
