import React from "react";
import * as s from "./ItsAllConected.module.scss";

const ItsAllConected = () => {
  return (
    <div className={s.container}>
      <div className={s.secondGroup}>
        <div className={s.firstGroup}>
          <p className={s.paragraph}>Taking Care of our Troop</p>
          <div className={s.secondParagraph}>
            Like the mycelial network, we are all connected. The underground
            root structure of mushrooms connects ecosystems and transmits
            nutrients through mycelial microscopic fibers. For instance, if a
            tree needs more nutrients than another, the mycelium gives more to
            that particular tree.
            <p>
              It’s all about giving back to the ecosystem you’re a part of. So
              from our troop to yours, let’s create a healthier ecosystem.
            </p>
            <p>
              Like this incredible network, we’re here to support all troops by
              helping you be the healthiest version of you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItsAllConected;
