import React from "react";
import * as s from "./AboutUsLogo.module.scss";
import jake from "../../../images/jakeB.jpg";

const AboutUsLogo = () => {
  return (
    <div className={s.container}>
      <div className={s.imageWrapper}>
        <img src={jake} alt="about-us"></img>
      </div>
      <div className={s.wrapper}>
        <p
          className={s.firstP}
          dangerouslySetInnerHTML={{ __html: "ABOUT <b>US</b>" }}
        ></p>
        <p className={s.secondP}>
          We’re all about the mush life, as such, we’re bringing the world of
          mushrooms to a friendly neighborhood near you. Perfect for any day
          that ends in ‘y,’ our mushroom gummies are ready for you morning, noon
          and night.
          <br />
          We’ve sourced the best ingredients and methods (slow clap for science)
          to make sure our gummies deliver on all things health and wellness
          (check out our FAQs for more on this), without sacrificing the fun and
          flavor of your new favorite gummies.
        </p>
        <p className={s.thirdP}>
          If you have any questions or just want to chat (we love a good chat),
          hit us up.
        </p>
      </div>
    </div>
  );
};

export default AboutUsLogo;
